<template>
    <section class="view account">
        <view-title v-bind:title="$t('message.account.title')">
            <template slot="footer">
                <ul class="nav nav-tabs">
                    <li class="nav-item">
                        <router-link class="nav-link" exact active-class="active"
                                     v-bind:to="{ name: 'account-info' }">
                            <font-awesome-icon class="mr-2" icon="user"></font-awesome-icon> {{ $t("message.account.dashboard") }}
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" exact active-class="active"
                                     v-bind:to="{ name: 'account-orders' }">
                            <font-awesome-icon class="mr-2" icon="shopping-cart"></font-awesome-icon> {{ $t("message.account.orders") }}
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" exact active-class="active"
                                     v-bind:to="{ name: 'account-bookings' }">
                            <font-awesome-icon class="mr-2" icon="calendar-alt"></font-awesome-icon> {{ $t("message.account.bookings") }}
                        </router-link>
                    </li>
                </ul>
            </template>
        </view-title>
        <router-view></router-view>
    </section>
</template>

<script>
    import ViewTitle from "../components/ViewTitle";
    export default {
        name: "Account",
        components: {ViewTitle},
        computed: {
            user () {
                return this.$store.state.user.user;
            },

            userStatus () {
                return this.$store.state.user.status;
            },
        },
        mounted () {
            if (!["loggedIn", "initial"].includes(this.userStatus)) {
                this.$router.push({
                    name: "login"
                });
            }
        },
        watch: {
            "userStatus" (newVal) {
                if (newVal) {
                    if (!["loggedIn", "initial"].includes(newVal)) {
                        this.$router.push({
                            name: "login"
                        })
                    }
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    section.account {
        .nav-tabs {
            flex-wrap: nowrap;
            overflow-x: auto;
            overflow-y: hidden;
        }

        .nav-item {
            flex: 1;
            text-align: center;
            border-top-left-radius: .25rem;
            border-top-right-radius: .25rem;
            min-width: 120px;

            & > .nav-link {
                white-space: nowrap;
            }

            &:first-child {
                margin-left: 35px;
            }

            &:last-child {
                margin-right: 35px;
            }
        }
    }
</style>
